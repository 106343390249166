import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IconButton } from '../../../../../general/controls/button/IconButton';
import { ShoppingCartIcon } from '@heroicons/react/16/solid';
import { useCartIndicatorQuery } from './hooks/useCartIndicatorQuery';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { useTranslationApply } from '../../../../../general/Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../../../general/Translations/hooks/useTranslation';

interface ICartIndicatorBubble {}

export const CartIndicatorBubble: React.FC<ICartIndicatorBubble> = () => {
    const { data: cart } = useCartIndicatorQuery();

    const tenantTheme = useRecoilValue(getTenantTheme);

    const init = useRef(false);

    // TODO - Implement animationOn
    const animationOn = false;

    const cartActiveCount = useMemo(() => {
        if (!cart || !cart.active) return 0;

        return cart?.active?.length ?? 0;
    }, [cart]);

    const [lastCount, setLastCount] = useState<null | number>(null);

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        if (!animationOn) return;

        if (lastCount === null && cartActiveCount !== 0) {
            setLastCount(cartActiveCount);
        }

        if (lastCount !== cartActiveCount && cartActiveCount !== 0 && lastCount !== 0) {
            if (!init.current) {
                init.current = true;
                return;
            }

            setShowAnimation(true);

            setTimeout(() => {
                setShowAnimation(false);
            }, 200);

            setLastCount(cartActiveCount);
        }
    }, [animationOn, cartActiveCount, lastCount]);

    return (
        <>
            {/* Cart Icon */}
            <div className="relative flex flex-col items-center justify-center gap-1">
                {cartActiveCount > 0 && (
                    <>
                        <div className={`absolute -top-1 right-1.5 md:-top-2`}>
                            {showAnimation && (
                                <div className="absolute -top-1 -left-1 h-10 w-10 animate-ping rounded-full bg-success" />
                            )}
                            <div
                                className={`md:text-md aspect-[1/1] h-6 absolute duration-200 transition-all flex p-1 flex-col items-center justify-center rounded-full bg-success md:rounded-full md:font-semibold ${
                                    showAnimation ? 'scale-125' : ''
                                }`}
                                style={{
                                    backgroundColor: tenantTheme.colors.success.DEFAULT,
                                }}
                            >
                                <span className="flex text-sm font-semibold text-white md:text-md">
                                    {cartActiveCount}
                                </span>
                            </div>
                        </div>
                    </>
                )}
                <IconButton
                    href="/cart?section=cart"
                    tooltip={
                        <span
                            {...applyTranslation({
                                key: 'headerCartButtonTip',
                            })}
                        >
                            {getT('headerCartButtonTip') ?? 'Zum Warenkorb'}
                        </span>
                    }
                >
                    <div className="relative">
                        <ShoppingCartIcon
                            className="relative h-5 align-middle text-primary"
                            style={{
                                color: tenantTheme.colors.primary.DEFAULT,
                            }}
                        />
                    </div>
                </IconButton>
            </div>
        </>
    );
};
