import React, { useMemo } from 'react';
import { CompanyHeader } from '../../../general/elements/companyHeader/CompanyHeader';
import { NavBarHeader } from './elements/navBarHeader/NavBarHeader';
import { UserHeader } from './elements/userHeader/UserHeader';
import { NavigationPanel } from './elements/navigationPanel/NavigationPanel';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { HeaderSearch } from './HeaderSearch';
import { loggedInUserSelector } from '../../../general/state/currentUser';
import { UserContextMenu } from '../../../general/elements/UserContextMenu/UserContextMenu';
import { CompanyHeaderLogoOnly } from '../../../general/elements/companyHeader/CompanyHeaderLogoOnly';
import { useThemeStyles } from '../../../general/theme/TenantTheme/hooks/useThemeStyles';
import { ThemeEditorModal } from '../../../general/themeEditorModal/ThemeEditorModal';

export interface IHeader {
    testId?: string;
    hideCategoryNavigation?: boolean;
    border?: boolean;
    fullWith?: boolean;
}

export const Header: React.FC<IHeader> = (props) => {
    const { testId, hideCategoryNavigation, border, fullWith } = props;

    const tenantTheme = useRecoilValue(getTenantTheme);
    const { applyStyles } = useThemeStyles();

    const borderStyles = useMemo(() => {
        if (border) {
            return 'border-b-2';
        }
        return '';
    }, [border]);

    const widthStyles = useMemo(() => {
        return fullWith ? '' : 'max-w-7xl';
    }, [fullWith]);

    const user = useRecoilValue(loggedInUserSelector);

    const userName = useMemo(() => {
        if (user?.fields?.generalFirstName && user?.fields?.generalLastName) {
            return `${user?.fields?.generalFirstName ?? ''} ${user?.fields?.generalLastName ?? ''}`;
        }

        return user?.userName ?? '';
    }, [user?.fields?.generalFirstName, user?.fields?.generalLastName, user?.userName]);

    const showWideLogoHeader = useMemo(() => {
        return tenantTheme.header.showWideLogoHeader ?? false;
    }, [tenantTheme.header.showWideLogoHeader]);

    return (
        <div
            id="Header"
            className={`relative flex w-screen flex-col items-center justify-center md:h-max ${borderStyles}`}
            data-test-id={testId}
            style={{
                ...applyStyles({
                    bgColor: 'colors.applications.header.background.DEFAULT',
                    borderColor: 'colors.applications.header.border.DEFAULT',
                }),
            }}
        >
            {/* Top NavBar */}
            <div
                className={`shadowHeader relative flex w-full flex-row items-center justify-between px-4 sm:px-6 md:h-headerUpperMd ${widthStyles}`}
                data-test-id={`${testId ?? ''}-top-navbar`}
            >
                {showWideLogoHeader && (
                    <div className="relative max-w-[300px]">
                        <CompanyHeaderLogoOnly src={tenantTheme.brand.logoWide?.url} />
                    </div>
                )}
                {!showWideLogoHeader && (
                    <CompanyHeader
                        src={tenantTheme.brand.logo.url}
                        companyName={tenantTheme.brand.companyName ?? ''}
                        companyClaim={tenantTheme.brand.claim ?? ''}
                        testId={testId}
                    />
                )}
                {tenantTheme.header?.showSearch && (
                    <div className="w-full lg:max-w-[400px]">
                        <HeaderSearch />
                    </div>
                )}
                <div className="relative my-auto w-max">
                    <NavBarHeader testId={testId} />
                </div>

                <div className="relative lg:w-[210px]">
                    <UserContextMenu
                        isAdminPanel={false}
                        verticalMode="down"
                        testId="header-user-context-menu"
                        showFAQ={true}
                    >
                        <UserHeader
                            userName={userName}
                            userImage={user?.picture}
                            showUserDetails={true}
                        />
                    </UserContextMenu>
                </div>
            </div>

            {/* Lower NavBar */}
            {!hideCategoryNavigation && (
                <div
                    className="relative flex w-full flex-col items-center"
                    data-test-id={`${testId ?? ''}-lower-navbar`}
                >
                    <NavigationPanel />
                    <ThemeEditorModal area="colors.applications.navigation" />
                </div>
            )}
        </div>
    );
};
