import React, { useCallback } from 'react';
import { EAccess } from '../../../../../../../interfaces/role/IAccess';
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid';
import { IconButtonSpecial } from '../../../../../../general/controls/button/IconButtonSpecial';
import { useTranslation } from '../../../../../../general/Translations/hooks/useTranslation';
import { useTranslationApply } from '../../../../../../general/Translations/hooks/useTranslationApply';
import { useSetRecoilState } from 'recoil';
import { adminShowTopLevelCategoriesSorter } from '../../../../../../admin/areas/categories/state/adminCategoryState';
import { AdminCategorySorterModal } from '../../../../../../admin/areas/categories/AdminCategorySorterModal';
import { useAdminControlsState } from '../../../../../../general/elements/adminControls/state/adminControls.state';
import { useAccess } from '../../../../../../general/access/hooks/useAccess';

interface INavigationPanelAdminButton {}

export const NavigationPanelAdminButton: React.FC<INavigationPanelAdminButton> = () => {
    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();
    const { hasAccess } = useAccess();
    const { productControls } = useAdminControlsState();

    const setShowSorter = useSetRecoilState(adminShowTopLevelCategoriesSorter);

    const handleOpen = useCallback(() => {
        setShowSorter(true);
    }, [setShowSorter]);

    return (
        <>
            {hasAccess([EAccess.mCat]) && (
                <div className="relative">
                    <IconButtonSpecial
                        access={[EAccess.mCat]}
                        onClick={() => handleOpen()}
                        target="cbadmin"
                        showControl={productControls}
                        tooltip={
                            <span
                                {...applyTranslation({
                                    key: 'adminCategoriesSpecialButtonTooltip',
                                })}
                            >
                                {getT('adminCategoriesSpecialButtonTooltip') ??
                                    'Edit top-level category sorting'}
                            </span>
                        }
                    >
                        <EllipsisHorizontalIcon className="h-5" />
                    </IconButtonSpecial>
                    <AdminCategorySorterModal />
                </div>
            )}
        </>
    );
};
