import React, { useCallback, useMemo } from 'react';
import { INavigationColumnItem, INavigationImageColumns } from './mocks/navigationItems';
import { useRecoilValue } from 'recoil';
import { IProduct } from '../../../../../../interfaces/product/IProduct';
import { useNavigation } from './hooks/useNavigation';
import {
    ERichTextStyles,
    RichTextDisplay,
} from '../../../../../general/controls/RichText/RichTextDisplay';
import { useCategoriesQuery } from '../../../../hooks/category/useCategoriesQuery';
import { getShowNavigationPanel } from '../../../../../general/state/appState';
import { useProductsAllQuery } from '../../../../../general/hooks/product/useProductsAllQuery';
import { tenantIDSelector } from '../../../../../general/state/tenantState';
import { NavigationPanelPreviewRandom } from './elements/NavigationPanelPreviewRandom';
import { useCategoryQuery } from '../../../../hooks/category/useCategoryQuery';
import { ECategoryContentDisplayMode } from '../../../../../admin/areas/categories/elements/AdminCategoryContentEditor';
import { NavigationPanelPreviewStaticList } from './elements/NavigationPanelPreviewStaticList';
import { NavigationPanelPreviewNewest } from './elements/NavigationPanelPreviewNewest';
import { useThemeStyles } from '../../../../../general/theme/TenantTheme/hooks/useThemeStyles';
import { useTranslationApply } from '../../../../../general/Translations/hooks/useTranslationApply';
import { ETranslationType } from '../../../../../general/Translations/enum/translations.enums';
import { ETranslationEntity } from '../../../../../general/Translations/types/translationApply.types';
import { useLocalisationState } from '../../../../../general/Translations/state/localisation.state';

interface INavigationPreviewColumn {
    columnData: INavigationColumnItem[];
    categoryId: string;
}

export const NavigationPreviewColumn: React.FC<INavigationPreviewColumn> = (props) => {
    const { columnData, categoryId } = props;

    const { data } = useCategoriesQuery();

    const { data: category } = useCategoryQuery(categoryId);

    const { applyStyles } = useThemeStyles();
    const { applyTranslation } = useTranslationApply();

    const showNavigationPanel = useRecoilValue(getShowNavigationPanel);

    const { userCurrentLanguage } = useLocalisationState();

    const currentCategory = useMemo(() => {
        return data?.find((categoryLocal) => {
            return categoryLocal.id === showNavigationPanel;
        });
    }, [data, showNavigationPanel]);

    const tenantID = useRecoilValue(tenantIDSelector);

    const currentDescriptionTranslation = useMemo(() => {
        if (!currentCategory) return undefined;

        if (!currentCategory.descriptionTranslation) {
            return currentCategory.description;
        }

        // Find translation based on current user userCurrentLanguage
        const translationEntry = currentCategory.descriptionTranslation.translations?.find(
            (translation) => translation.languageCode === userCurrentLanguage?.languageCode
        );

        if (translationEntry) {
            return translationEntry.translation;
        }

        // If no translation found for current language, return the base translation
        return (
            currentCategory.descriptionTranslation.baseTranslation ||
            currentCategory.description ||
            ''
        );
    }, [currentCategory, userCurrentLanguage]);

    const { data: products } = useProductsAllQuery({
        tenantID: tenantID ? tenantID : undefined,
    });

    const getProductByCategoryId = useCallback(
        (categoryIdLocal: string): IProduct[] | null => {
            return (
                products?.filter((product) => {
                    return product.categoryIDs.includes(categoryIdLocal);
                }) ?? null
            );
        },
        [products]
    );

    const { selectedNavigationTab, closeNavigation } = useNavigation();

    const imageColumns = useMemo((): INavigationImageColumns | null => {
        if (!products) return null;

        const productsInColumn: IProduct[] = [];

        columnData.forEach((columnItem) => {
            if (columnItem.categoryID) {
                const productsInColumnItem = getProductByCategoryId(columnItem.categoryID);
                if (productsInColumn !== undefined) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    productsInColumn.push(...productsInColumnItem);
                }
            }
        });

        const output: INavigationImageColumns = {
            headline: selectedNavigationTab?.headline ?? '',
            description: '',
            productDemos: [],
        };

        // Get 0-3 random products from the column
        const randomProducts = productsInColumn
            .sort(() => Math.random() - Math.random())
            .slice(0, 3);

        // Set the images 1-3
        for (let i = 0; i < 3; i++) {
            if (randomProducts[i]) {
                output.productDemos.push(randomProducts[i] as IProduct);
            }
        }

        return output;
    }, [columnData, getProductByCategoryId, products, selectedNavigationTab?.headline]);

    const displayMode: ECategoryContentDisplayMode = useMemo(() => {
        return (
            category?.presentationConfig?.displayMode ?? ECategoryContentDisplayMode.RANDOM_PRODUCTS
        );
    }, [category?.presentationConfig?.displayMode]);

    const headlineInCurrentUserLanguage: string = useMemo(() => {
        if (!category) return '';

        // Check if the category name has translations
        if (!category.nameTranslation) {
            return category.name || '';
        }

        // Find translation based on current user language
        const translationEntry = category.nameTranslation.translations?.find(
            (translation) => translation.languageCode === userCurrentLanguage?.languageCode
        );

        if (translationEntry) {
            return translationEntry.translation;
        }

        // If no translation found for current language, return the base translation
        return category.nameTranslation.baseTranslation || category.name || '';
    }, [category, userCurrentLanguage]);

    return (
        <div
            className="relative grid h-full w-full grid-cols-2 gap-4 p-6"
            style={{
                ...applyStyles({
                    bgColor: 'colors.applications.navigation.background.sideSection',
                }),
            }}
            data-test-id={'navigation-preview'}
        >
            {/* Headline and Text */}
            <div className="relative flex h-full w-full flex-col gap-6">
                {/* Headline */}
                <span
                    className="w-full text-xl font-semibold text-invert-primary"
                    style={{
                        ...applyStyles({
                            textColor: 'colors.applications.navigation.text.sideMenuItems',
                        }),
                    }}
                    data-test-id={'navigation-preview-headline'}
                >
                    {/* HEADLINE */}
                    {headlineInCurrentUserLanguage ?? imageColumns?.headline ?? ''}
                </span>

                {/* Descriptive Text */}
                <div
                    className="relative w-full"
                    style={{
                        ...applyStyles({
                            textColor: 'colors.applications.navigation.text.sideMenuItems',
                        }),
                    }}
                    data-test-id={'navigation-preview-description'}
                >
                    {currentCategory && currentDescriptionTranslation && (
                        <RichTextDisplay
                            content={currentDescriptionTranslation}
                            invertStyles={true}
                            styles={ERichTextStyles.pageInvertStyles}
                        />
                    )}
                    <div
                        className="absolute top-0 left-0 h-full w-full"
                        {...(currentCategory &&
                            applyTranslation({
                                key: currentCategory.id,
                                type: ETranslationType.categoryDescription,
                                entity: ETranslationEntity.richTextSlate,
                            }))}
                    ></div>
                </div>
            </div>

            {/* Images */}
            {products &&
                category &&
                displayMode === ECategoryContentDisplayMode.RANDOM_PRODUCTS && (
                    <NavigationPanelPreviewRandom
                        products={products}
                        columnData={columnData}
                        headline={selectedNavigationTab?.headline ?? ''}
                        closeNavigation={function (): void {
                            closeNavigation();
                        }}
                    />
                )}

            {products && category && displayMode === ECategoryContentDisplayMode.STATIC_LIST && (
                <NavigationPanelPreviewStaticList
                    products={products}
                    category={category}
                    columnData={columnData}
                    headline={selectedNavigationTab?.headline ?? ''}
                    closeNavigation={function (): void {
                        closeNavigation();
                    }}
                />
            )}
            {products &&
                category &&
                displayMode === ECategoryContentDisplayMode.NEWEST_PRODUCTS && (
                    <NavigationPanelPreviewNewest
                        products={products}
                        columnData={columnData}
                        headline={selectedNavigationTab?.headline ?? ''}
                        closeNavigation={function (): void {
                            closeNavigation();
                        }}
                    />
                )}
        </div>
    );
};
