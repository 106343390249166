import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { useLocalisation } from '../../../../../general/hooks/translations/useLocalisation';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { useTranslationApply } from '../../../../../general/Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../../../general/Translations/hooks/useTranslation';
import { useThemeStyles } from '../../../../../general/theme/TenantTheme/hooks/useThemeStyles';
import { useLocalisationState } from '../../../../../general/Translations/state/localisation.state';
import { getCountryCodeFromLocale } from '../../../../../general/Translations/helper/getCountryCodeFromLocale';

export interface IUserHeader {
    userName: string;
    userImage?: string | null;
    showUserDetails?: boolean;
}

export const UserHeader: React.FC<IUserHeader> = (props) => {
    const { userName, userImage, showUserDetails } = props;

    const tenantTheme = useRecoilValue(getTenantTheme);

    const { applyStyles } = useThemeStyles();

    const { userCurrentLanguage } = useLocalisationState();

    const { translations } = useLocalisation();
    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const gridStyles = useMemo((): string => {
        if (!showUserDetails) {
            return 'flex flex-col gap-1';
        }

        return 'grid grid-cols-[50px,auto] gap-1 ';
    }, [showUserDetails]);

    const sizingStylesWelcome = useMemo((): string => {
        if (userName.length > 20) {
            return 'text-[10px] tracking-wide';
        }

        return 'text-sm tracking-wide';
    }, [userName.length]);

    const sizingStyles = useMemo((): string => {
        if (userName.length > 20) {
            return 'text-[10px] leading-tight ellipsis overflow-hidden';
        }

        return 'text-sm';
    }, [userName.length]);

    const currentLanguage = useMemo(() => {
        if (!userCurrentLanguage?.languageCode) return null;
        return getCountryCodeFromLocale(userCurrentLanguage?.languageCode) ?? null;
    }, [userCurrentLanguage?.languageCode]);

    return (
        <div
            className={`relative w-max max-w-full ${gridStyles}`}
            data-test-id="user-header-wrapper"
        >
            {/* Avatar */}
            <div className="relative">
                {tenantTheme.header?.showAvatar && (
                    <div className="flex flex-col items-center justify-center aspect-[1/1] h-[40px] w-[40px] md:h-[45px] md:w-full">
                        <img
                            src={userImage ? userImage : '/assets/svg/defaultAvatar.svg'}
                            alt={`${userName ?? 'user'} avatar`}
                            width={45}
                            height={45}
                            className="w-full object-cover"
                            data-test-id="user-header-avatar"
                        />
                    </div>
                )}
                {currentLanguage && (
                    <div className="absolute -bottom-2 -left-1 flex h-5 w-5 items-center justify-center overflow-hidden rounded-full border-2 border-white">
                        <img
                            className="relative block h-4"
                            src={`/assets/flags/4x3/${currentLanguage}.svg`}
                            alt={currentLanguage}
                            style={{
                                maxWidth: 'none',
                            }}
                        />
                    </div>
                )}
            </div>

            {/* User Details */}
            {showUserDetails && tenantTheme.header?.showUserDetails && (
                <div className="relative flex flex-col items-start justify-center">
                    <span
                        className={`text-body-light ${sizingStylesWelcome}`}
                        data-test-id="user-header-welcome"
                        {...applyTranslation({
                            key: 'headerWelcome',
                        })}
                        style={{
                            ...applyStyles({
                                textColor:
                                    tenantTheme.colors.applications.header.text.userContextLight,
                            }),
                        }}
                    >
                        {getT('headerWelcome') ?? 'Herzlich willkommen'}
                    </span>
                    <span
                        className={`font-bold text-body-dark ${sizingStyles}`}
                        data-test-id="user-header-username"
                        style={{
                            ...applyStyles({
                                textColor:
                                    tenantTheme.colors.applications.header.text.userContextPrimary,
                            }),
                        }}
                    >
                        {userName}
                    </span>
                </div>
            )}

            {!tenantTheme.header?.showUserDetails && !tenantTheme.header?.showAvatar && (
                <div
                    className="relative flex flex-row items-center justify-center gap-1 text-primary"
                    style={{ color: tenantTheme.colors.primary.DEFAULT }}
                    data-test-id="user-header-chevron"
                >
                    {translations.menu ?? ''}
                    <ChevronDownIcon
                        className="h-4 text-neutral-400"
                        style={{ color: tenantTheme.colors.body.light }}
                    />
                </div>
            )}
        </div>
    );
};
