import { useCallback, useMemo } from 'react';
import { INavigationTab, useNavigationItems } from '../mocks/navigationItems';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { appStateRecoil, getShowNavigationPanel } from '../../../../../../general/state/appState';
import { useBlockScrollLayer } from '../../../../../../general/hooks/helper/useBlockScrollLayer';

export const useNavigation = () => {
    const navigationItems = useNavigationItems();
    const showNavigationPanel = useRecoilValue(getShowNavigationPanel);
    const setAppState = useSetRecoilState(appStateRecoil);
    const { resetScroll } = useBlockScrollLayer();

    const selectedNavigationTab = useMemo((): INavigationTab | null => {
        // check all navigationItems if the id matches showNavigationPanel
        const id = navigationItems.find((tab) => {
            if (tab.id === showNavigationPanel) {
                return tab;
            }
        });

        if (!id) return null;

        return id;
    }, [navigationItems, showNavigationPanel]);

    const closeNavigation = useCallback(() => {
        setAppState((currVal) => {
            return { ...currVal, showNavigationPanel: undefined };
        });
        resetScroll();
    }, [setAppState, resetScroll]);

    return { selectedNavigationTab, closeNavigation };
};
