import React, { Fragment, useMemo } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import { computedStylesRecoil } from '../theme/ComputedStyles/recoil/computedStyles.recoil';
import { get } from 'lodash';

export interface IThemeEditorModal {
    area: string | null;
}

export interface IThemeEditorItem {
    [key: string]: string | IThemeEditorItem;
}

export const ThemeEditorModal: React.FC<IThemeEditorModal> = (props) => {
    const { area = null } = props;

    const active = false;

    const uiConfig = useRecoilValue(computedStylesRecoil).theme;

    const themeEditorItems = useMemo(() => {
        if (!uiConfig || !area) return null;
        const part: unknown = get(uiConfig, area);
        return part as IThemeEditorItem;
    }, [area, uiConfig]);

    return (
        <>
            {active && (
                <Popover className="relative" data-test-id="theme-editor-modal">
                    {() => {
                        // if (onChange) {
                        //     onChange(popover.open);
                        // }

                        return (
                            <>
                                <Popover.Button className="relative flex w-full justify-center items-left">
                                    REMOVE{themeEditorItems?.toString()}
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="">
                                        <nav></nav>
                                        POINTS
                                    </Popover.Panel>
                                </Transition>
                            </>
                        );
                    }}
                </Popover>
            )}
        </>
    );
};
