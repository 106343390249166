import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useApi } from '../../../../../../general/hooks/api/useApi';
import { ICartBE } from '../../../../../../../interfaces/cart/ICartBE';

export const useCartIndicatorQuery = () => {
    const { postGql } = useApi();

    return useQuery({
        queryKey: ['cartIndicator'],
        queryFn: async () => {
            const { cart } = await postGql<{ cart: ICartBE }>(
                'cartIndicator',
                gql`
                    query {
                        cart {
                            active {
                                id
                            }
                        }
                    }
                `
            );

            return cart;
        },
    });
};
