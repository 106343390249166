import React from 'react';
import { INavigationColumnItem } from './mocks/navigationItems';
import { NavigationItemLink } from './NavigationItemLink';

export interface INavigationColumn {
    closeFunction: () => void;
    index: number;
    item: INavigationColumnItem;
    columnOrder: number;
}

export const NavigationColumnItem: React.FC<INavigationColumn> = (props) => {
    const { closeFunction, index, item, columnOrder } = props;

    return (
        <NavigationItemLink
            link={item.link ?? ''}
            label={item.label}
            nameTranslation={item.category?.nameTranslation}
            categoryId={item.category?.id}
            closeFunction={closeFunction}
            columnOrder={columnOrder}
            index={index}
        />
    );
};
