import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    categoriesSelector,
    categoryIdSelector,
    categoryProductsSelector,
    currentItemSelector,
    productIdSelector,
    selectedProductSelector,
} from '../state/catalogState';
import { tenantIDSelector } from '../../../../general/state/tenantState';
import { useProductsAllQuery } from '../../../../general/hooks/product/useProductsAllQuery';
import { useCategoriesQuery } from '../../../hooks/category/useCategoriesQuery';
import { IProduct } from '../../../../../interfaces/product/IProduct';
import { IProductsGetInput } from '../../../../../interfaces/product/api/IProductsGetInput';

export const useCatalog = () => {
    const [productId, setProductId] = useRecoilState(productIdSelector);
    const [selectedProduct, setSelectedProduct] = useRecoilState(selectedProductSelector);

    const [categories, setCategories] = useRecoilState(categoriesSelector);
    const [categoryId, setCategoryId] = useRecoilState(categoryIdSelector);
    const [categoryProducts, setCategoryProducts] = useRecoilState(categoryProductsSelector);

    const [currentItem, setCurrentItem] = useRecoilState(currentItemSelector);

    const category = useMemo(() => {
        return categories.find((el) => el.id === categoryId);
    }, [categories, categoryId]);

    const tenantID = useRecoilValue(tenantIDSelector);

    const productsInput = useMemo((): IProductsGetInput => {
        return {
            tenantID: tenantID ? tenantID : undefined,
            take: 300,
            skip: 0,
        };
    }, [tenantID]);

    const { data: productsAll, isFetching: isFetchingProducts } =
        useProductsAllQuery(productsInput);

    const { data: categoriesAll, isFetching: isFetchingCategories } = useCategoriesQuery();

    useEffect(() => {
        if (categoriesAll) {
            setCategories(categoriesAll);
        }
    }, [categoriesAll, setCategories]);

    useEffect(() => {
        if (productsAll && categoryId) {
            const foundedCategoryProducts: IProduct[] = [];

            // Iterate over all categories
            categories.map((categoryElement) => {
                // If the category ID matches the selected category ID, then iterate over all product IDs
                if (categoryElement.id === categoryId) {
                    // Iterate over all product IDs and find the product in the products array
                    categoryElement.productIDs?.forEach((productItem) => {
                        foundedCategoryProducts.push(
                            productsAll.find((el) => el.id === productItem) as IProduct
                        );
                    });
                }
            });

            // Set the category products
            setCategoryProducts(foundedCategoryProducts);
        }
    }, [productsAll, categoryId, setCategoryProducts, setSelectedProduct, categories]);

    useEffect(() => {
        if (productsAll && productId) {
            const foundedProduct = productsAll.find((el) => el.id === productId);
            if (foundedProduct) {
                setSelectedProduct(foundedProduct);
            }
        }
    }, [productId, productsAll, setSelectedProduct]);

    const getProductById = useCallback(
        (id: string): IProduct | undefined => {
            if (productsAll?.length) {
                return productsAll.find((product) => product.id === id);
            }
            return undefined;
        },
        [productsAll]
    );

    const ready = useMemo(() => productsAll !== null, [productsAll]);

    return {
        products: productsAll,
        isFetchingProducts,
        productId,
        setProductId,
        selectedProduct,
        setSelectedProduct,
        ready,
        currentItem,
        setCurrentItem,
        categories,
        isFetchingCategories,
        categoryId,
        setCategoryId,
        category,
        categoryProducts,

        getProductById,
    };
};
