import React, { useMemo } from 'react';
import Link from 'next/link';
import { useThemeStyles } from '../../../../../../general/theme/TenantTheme/hooks/useThemeStyles';
import { INavigationTab } from '../mocks/navigationItems';
import { useSetRecoilState } from 'recoil';
import { appStateRecoil } from '../../../../../../general/state/appState';
import { useTranslationGetContent } from '../../../../../../general/Translations/hooks/useTranslationGetContent';
import { useTranslationApply } from '../../../../../../general/Translations/hooks/useTranslationApply';
import { ETranslationType } from '../../../../../../general/Translations/enum/translations.enums';

interface INavigationPanelTopItem {
    tab: INavigationTab;
    closeNavigation: () => void;
    isSelected: boolean;
    index: number;
}

export const NavigationPanelTopItem: React.FC<INavigationPanelTopItem> = (props) => {
    const { tab, closeNavigation, isSelected, index } = props;

    const setAppState = useSetRecoilState(appStateRecoil);
    const { getTranslationContent } = useTranslationGetContent();
    const { applyTranslation } = useTranslationApply();

    const { applyStyles } = useThemeStyles();

    const translatedTitle = useMemo(() => {
        return getTranslationContent(tab.nameTranslation, tab.title ?? '');
    }, [getTranslationContent, tab.nameTranslation, tab.title]);

    const borderStyles = useMemo(() => {
        return isSelected ? 'border-border' : 'border-transparent';
    }, [isSelected]);

    const isFirst = useMemo(() => {
        return index === 0;
    }, [index]);

    const paddings: string = useMemo(() => {
        if (isFirst) {
            return 'pl-0 pr-2 py-1';
        }

        return 'px-2 py-1';
    }, [isFirst]);

    const translationProps = useMemo(() => {
        if (!tab.id) return undefined;

        return applyTranslation({
            key: tab.id,
            type: ETranslationType.categoryName,
        });
    }, [applyTranslation, tab.id]);

    return (
        <Link
            className={`relative flex h-10 w-max items-center border-b-2 font-semibold transition-all hover:border-border ${paddings} ${borderStyles}`}
            key={`navigation-tab-${tab.id}`}
            data-test-id={`navigation-item-${tab.id}`}
            style={{
                ...applyStyles({
                    textColor: 'colors.applications.navigation.text.topMenuItems',
                }),
            }}
            onClick={(e) => {
                // Only close navigation if we're not clicking on a translatable element
                if (!(e.target as HTMLElement).hasAttribute('data-translation')) {
                    closeNavigation();
                }
            }}
            href={tab.href ?? '#'}
            onMouseOver={() => {
                setAppState((currVal) => {
                    return {
                        ...currVal,
                        showNavigationPanel: tab.id ?? undefined,
                    };
                });
            }}
        >
            {/* Separate span for translation to ensure click handler is properly applied */}
            <span {...translationProps}>
                {translatedTitle?.replace(/<[^>]*>/g, '') ?? ''}
            </span>
        </Link>
    );
};
