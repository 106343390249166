import { ICategory, ICategoryDB } from '../../../../interfaces/category/ICategory'; // import ICategory for correct types
import { gql } from 'graphql-request';
import { useApi } from '../../../general/hooks/api/useApi';
import { useQuery, UseQueryResult } from '@tanstack/react-query'; // import UseQueryResult for correct types
import { mapCategoryFromDBToFE } from '../../../../interfaces/category/maps';

export const useCategoriesQuery = (): UseQueryResult<ICategory[], unknown> => {
    // specify return type of the function
    const { postGql } = useApi();

    const mapCategoriesRecursive = (
        categories: ICategoryDB[],
        category: ICategoryDB
    ): ICategory => {
        // changed return type to ICategory
        // TODO !!! Needs to be mapped correctly
        // @ts-ignore
        category.children = categories
            .filter((cat) => cat.parentCategoryID === category.id)
            .map((cat) => mapCategoriesRecursive(categories, cat));
        return mapCategoryFromDBToFE(category);
    };

    return useQuery<ICategory[], unknown>({
        // ensure that useQuery returns ICategory[] instead of ICategoryDB[]
        staleTime: 60000,
        queryKey: ['categories'],
        queryFn: async () => {
            const { categories } = await postGql<{ categories: ICategoryDB[] }>(
                'categories',
                gql`
                    query categories($input: CategoriesGetInput!) {
                        categories(input: $input) {
                            id
                            name
                            description
                            parentCategoryID
                            orderNo
                            accessRoleIDs
                            published
                            code
                            productIDs
                            tenantIDs
                            nameTranslation {
                                baseTranslation
                                languageCode
                                translationKey
                                group
                                tags
                                translations {
                                    languageCode
                                    translation
                                }
                            }
                            descriptionTranslation {
                                baseTranslation
                                languageCode
                                translationKey
                                group
                                tags
                                translations {
                                    languageCode
                                    translation
                                }
                            }
                        }
                    }
                `,
                { input: {} }
            );

            return Promise.all(categories.map((cat) => mapCategoriesRecursive(categories, cat)));
        },
    });
};
