import React, { PropsWithChildren, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useKeyPress } from 'react-use';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../theme/ComputedStyles/recoil/computedStyles.recoil';
import { useBlockScrollLayer } from '../hooks/helper/useBlockScrollLayer';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { IconButton } from '../controls/button/IconButton';

export interface IModalFullScreen extends PropsWithChildren {
    show?: boolean;
    closeFunction: () => void;
    displayTopHeader?: boolean;
    centerItems?: boolean;
    centerHorizontal?: boolean;
    hideXButton?: boolean;
    admin?: boolean;
}

export const ModalFullScreen: React.FC<IModalFullScreen> = (props) => {
    const {
        show,
        closeFunction,
        displayTopHeader,
        admin,
        centerItems,
        centerHorizontal,
        hideXButton,
    } = props;

    const predicate = (event: KeyboardEvent) => event.key === 'Escape';
    const escPressed = useKeyPress(predicate);

    const tenantTheme = useRecoilValue(getTenantTheme);

    const { blockScroll, resetScroll } = useBlockScrollLayer();

    useEffect(() => {
        if (show) {
            blockScroll();
        }
    }, [blockScroll, show]);

    useEffect(() => {
        if (show && escPressed && escPressed[0]) {
            resetScroll();
            closeFunction();
        }
    }, [closeFunction, escPressed, resetScroll, show]);

    return (
        <>
            {/*Activate Prevent Click and Scroll layer*/}
            {show && (
                <div
                    className="fixed top-0 left-0 z-30 h-20 w-screen"
                    onClick={() => {
                        closeFunction();
                        resetScroll();
                    }}
                />
            )}

            {/* FIX should use variables in calc height below*/}
            <Transition
                as={'section'}
                className={`fixed left-0 z-30 flex h-screen w-screen overflow-x-hidden duration-100 ${
                    displayTopHeader
                        ? 'top-headerUpperDefault h-[calc(100vh-2.625em)] md:top-headerUpperMd md:h-[calc(100vh-3em)]'
                        : 'top-[0px] h-screen'
                } z-40 w-screen overflow-hidden ${!admin ? 'backdrop-blur-2xl' : 'bg-white'}`}
                show={show ?? false}
                appear={show ?? false}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-20"
                enterTo="transform opacity-100"
                leave="transition ease-in duration-200 duration-100"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
            >
                {/*Close Button*/}

                <div className="absolute top-4 right-6 z-40">
                    {!hideXButton && (
                        <IconButton
                            onClick={() => {
                                resetScroll();
                                closeFunction();
                            }}
                            icon={
                                <XMarkIcon
                                    className={`h-6 text-text-light hover:text-body-disabled`}
                                    style={{ color: tenantTheme.colors.body.dark }}
                                />
                            }
                        />
                    )}
                </div>
                {/*Inner Wrapper*/}
                <div
                    className={`relative ${
                        displayTopHeader
                            ? 'h-[calc(100vh-2.625em)] md:h-[calc(100vh-3em)]'
                            : 'h-full'
                    } flex w-full ${centerItems ? 'items-center justify-center' : ''}
                         ${centerHorizontal ? 'justify-center' : ''}
                            overflow-y-auto`}
                >
                    {props.children}
                </div>
            </Transition>
        </>
    );
};

export default ModalFullScreen;
