import React, { useCallback, useMemo } from 'react';
import { INavigationColumnItem, INavigationImageColumns } from '../mocks/navigationItems';
import { IProduct } from '../../../../../../../interfaces/product/IProduct';
import { CatalogItem } from '../../../../../elements/CatalogItem/CatalogItem';

interface INavigationPanelNewest {
    products: IProduct[];
    columnData: INavigationColumnItem[];
    headline: string;
    closeNavigation: () => void;
}

export const NavigationPanelPreviewNewest: React.FC<INavigationPanelNewest> = (props) => {
    const { products, closeNavigation, columnData, headline } = props;

    const getProductByCategoryId = useCallback(
        (categoryId: string): IProduct[] | null => {
            return (
                products
                    ?.filter((product) => product.published)
                    .filter((product) => {
                        return product.categoryIDs.includes(categoryId);
                    }) ?? null
            );
        },
        [products]
    );

    const imageColumns = useMemo((): INavigationImageColumns | null => {
        if (!products) return null;

        const productsInColumn: IProduct[] = [];

        columnData.forEach((columnItem) => {
            if (columnItem.categoryID) {
                const productsInColumnItem = getProductByCategoryId(columnItem.categoryID);
                if (productsInColumn !== undefined) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    productsInColumn.push(...productsInColumnItem);
                }
            }
        });

        const output: INavigationImageColumns = {
            headline: headline ?? '',
            description: '',
            productDemos: [],
        };

        // sort productsInColumn by created:isoDate
        productsInColumn.sort((a, b) => {
            return new Date(b.created).getTime() - new Date(a.created).getTime();
        });

        // Set the images 1-3
        for (let i = 0; i < 3; i++) {
            if (productsInColumn[i]) {
                output.productDemos.push(productsInColumn[i] as IProduct);
            }
        }

        return output;
    }, [columnData, getProductByCategoryId, products, headline]);

    return (
        <>
            <section
                className="relative grid w-full grid-cols-2 gap-2"
                data-test-id={'navigation-preview-images-wrapper'}
            >
                {imageColumns?.productDemos.map((product, index) => {
                    return (
                        <div
                            key={'demo-product-' + product.id}
                            className={`${index === 0 ? 'col-span-2' : ''} z-30`}
                        >
                            <CatalogItem
                                product={product}
                                small={true}
                                shortText={index > 0}
                                showFavourite={false}
                                onClick={closeNavigation}
                                showIconBar={index === 0}
                                isSortable={false}
                                showTags={index === 0}
                                showLabels={index === 0}
                            />
                        </div>
                    );
                })}
            </section>
        </>
    );
};
