import React, { useEffect, useMemo, useState } from 'react';
import { Transition } from '@headlessui/react';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { useThemeStyles } from '../../../../../general/theme/TenantTheme/hooks/useThemeStyles';
import { useTranslationGetContent } from '../../../../../general/Translations/hooks/useTranslationGetContent';
import { ITranslations } from '../../../../../../interfaces/translation/translations.interfaces';
import { useTranslationApply } from '../../../../../general/Translations/hooks/useTranslationApply';
import { ETranslationType } from '../../../../../general/Translations/enum/translations.enums';

export interface INavigationItemLink {
    index: number;
    columnOrder: number;
    label: string;
    link: string;
    closeFunction: () => void;
    nameTranslation?: ITranslations;
    categoryId?: string;
}

export const NavigationItemLink: React.FC<INavigationItemLink> = (props) => {
    const { index, columnOrder, link, closeFunction, label, nameTranslation, categoryId } = props;

    const [show, setShow] = useState(false);

    const { applyStyles } = useThemeStyles();
    const { getTranslationContent } = useTranslationGetContent();
    const { applyTranslation } = useTranslationApply();

    const translatedLabel = useMemo(() => {
        return getTranslationContent(nameTranslation, label);
    }, [getTranslationContent, nameTranslation, label]);

    const tenantTheme = useRecoilValue(getTenantTheme);

    useEffect(() => {
        if (show) return;

        setTimeout(
            () => {
                setShow(true);
            },
            index * 20 * columnOrder
        );
    }, [columnOrder, index, show]);

    const translationProps = useMemo(() => {
        // If we have a category ID, use that directly as it will be used
        // by useTranslationEditorFormPopulateCategoryName to fetch the category data
        if (categoryId) {
            return applyTranslation({
                key: categoryId,
                type: ETranslationType.categoryName,
            });
        }

        // Fallback to using the translationKey or generated key
        const key =
            nameTranslation?.translationKey ||
            (nameTranslation?.baseTranslation &&
                `category_${label.replace(/\s+/g, '_').toLowerCase()}`);

        if (!key) return undefined;

        return applyTranslation({
            key: key,
            type: ETranslationType.categoryName,
        });
    }, [applyTranslation, nameTranslation, label, categoryId]);

    return (
        <Transition
            key={`navigation-item-${index}`}
            as={'div'}
            className="duration-50"
            show={show}
            appear={show}
            enter="transition ease-out"
            enterFrom="transform opacity-20 -translate-y-[20px]"
            enterTo="transform opacity-100 translate-y-[0px]"
            leave="transition ease-in"
            leaveFrom="transform opacity-100 taanslate-y-0"
            leaveTo="transform opacity-0 translate-y-[20px]"
            data-test-id="lower-navigation"
        >
            <div
                className="relative flex w-full flex-col gap-2 max-w-[170px]"
                style={{
                    color: tenantTheme.colors.primary.DEFAULT,
                }}
            >
                <Link
                    className="relative flex w-full flex-col gap-1 border-b-2 border-transparent px-2 py-1 font-semibold transition-all hover:border-border"
                    data-test-id={`navigation-item-${label ?? ''}`}
                    style={{
                        ...applyStyles({
                            textColor: 'colors.applications.navigation.text.subMenuItems',
                        }),
                    }}
                    onClick={(e) => {
                        if (!(e.target as HTMLElement).hasAttribute('data-translation')) {
                            closeFunction();
                        }
                    }}
                    href={link ?? ''}
                >
                    {/* Separate span for translation to ensure click handler is properly applied */}
                    <span {...translationProps}>
                        {translatedLabel?.replace(/<[^>]*>/g, '') ?? ''}
                    </span>
                </Link>
            </div>
        </Transition>
    );
};
