import { useSetRecoilState } from 'recoil';
import { appStateRecoil } from '../../state/appState';
import { useCallback } from 'react';

export const useBlockScrollLayer = () => {
    const setAppState = useSetRecoilState(appStateRecoil);

    const blockScroll = useCallback(() => {
        setAppState((currVal) => {
            return {
                ...currVal,
                wrapperAdditionalClasses:
                    'fixed top-0 left-0 z-20 h-screen w-screen overflow-hidden',
            };
        });
    }, [setAppState]);

    const resetScroll = useCallback(() => {
        setAppState((currVal) => {
            return {
                ...currVal,
                wrapperAdditionalClasses: null,
            };
        });
    }, [setAppState]);

    return { blockScroll, resetScroll };
};
