import React, { useEffect, useState } from 'react';
import { INavigationColumnItem } from './mocks/navigationItems';
import { NavigationColumnItem } from './NavigationColumnItem';

export interface INavigationColumn {
    closeFunction: () => void;
    column: INavigationColumnItem[];
    columnOrder: number;
}

export const NavigationColumn: React.FC<INavigationColumn> = (props) => {
    const { column, closeFunction, columnOrder } = props;

    const [show, setShow] = useState(false);

    useEffect(() => {
        if (show) return;

        setTimeout(() => {
            setShow(true);
        });
    }, [show]);

    return (
        <div className="relative flex w-full flex-col gap-4 p-6">
            {/* Render Items per column */}
            {column.map((item, indexItem) => {
                return (
                    <NavigationColumnItem
                        key={`navigation-column-item-${indexItem}`}
                        closeFunction={closeFunction}
                        item={item}
                        index={indexItem}
                        columnOrder={columnOrder}
                    />
                );
            })}
        </div>
    );
};
