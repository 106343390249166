import React, { useMemo } from 'react';
import { INavigationColumnItem } from '../mocks/navigationItems';
import { IProduct } from '../../../../../../../interfaces/product/IProduct';
import { ICategory } from '../../../../../../../interfaces/category/ICategory';
import { CatalogItem } from '../../../../../elements/CatalogItem/CatalogItem';

interface INavigationPanelPreviewStaticList {
    products: IProduct[];
    columnData: INavigationColumnItem[];
    headline: string;
    closeNavigation: () => void;
    category: ICategory;
}

export const NavigationPanelPreviewStaticList: React.FC<INavigationPanelPreviewStaticList> = (
    props
) => {
    const { products, closeNavigation, category } = props;

    const selectedProducts: IProduct[] = useMemo(() => {
        if (category && category.presentationConfig?.staticProductIDs) {
            // Get the staticProductIDs array
            const { staticProductIDs } = category.presentationConfig;

            // Filter and sort products based on their order in staticProductIDs
            return products
                .filter((product) => product.published)
                .filter((product) => staticProductIDs.includes(product.id))
                .sort((a, b) => staticProductIDs.indexOf(a.id) - staticProductIDs.indexOf(b.id));
        }
        return [];
    }, [category, products]);

    return (
        <>
            <section
                className="relative grid w-full grid-cols-2 gap-2"
                data-test-id={'navigation-preview-images-wrapper'}
            >
                {selectedProducts.map((product, index) => {
                    return (
                        <div
                            key={'demo-product-' + product.id}
                            className={`${index === 0 ? 'col-span-2' : ''} z-30`}
                        >
                            <CatalogItem
                                product={product}
                                small={true}
                                shortText={index > 0}
                                showFavourite={false}
                                onClick={closeNavigation}
                                showIconBar={index === 0}
                                isSortable={false}
                                showTags={index === 0}
                                showLabels={index === 0}
                            />
                        </div>
                    );
                })}
            </section>
        </>
    );
};
