import React from 'react';
import { nanoid } from 'nanoid';
import Link from 'next/link';

import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../../../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { hasNewMessagesSelector } from '../../../../../general/state/appState';
import { IconButton } from '../../../../../general/controls/button/IconButton';
import { EnvelopeIcon, StarIcon } from '@heroicons/react/16/solid';
import { CartIndicatorBubble } from '../CartIndicatorBubble/CartIndicatorBubble';
import { useTranslationApply } from '../../../../../general/Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../../../general/Translations/hooks/useTranslation';

export interface INavBarHeader {
    testId?: string;
}

export const NavBarHeader: React.FC<INavBarHeader> = (props) => {
    const { testId } = props;
    const tenantTheme = useRecoilValue(getTenantTheme);

    const hasNewMessages = useRecoilValue(hasNewMessagesSelector);

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    return (
        <div
            className="relative flex h-full w-full flex-row items-center justify-center gap-4 md:gap-7"
            data-test-id={`${testId ?? nanoid()}`}
        >
            {/* Favourite Icon */}
            {tenantTheme.header?.showFavorites && (
                <IconButton
                    href="/favourites"
                    tooltip={
                        <span
                            {...applyTranslation({
                                key: 'headerFavouriteButtonTip',
                            })}
                        >
                            {getT('headerFavouriteButtonTip') ?? 'Meine Favoriten'}
                        </span>
                    }
                >
                    <StarIcon
                        className="relative h-5 align-middle text-primary"
                        style={{
                            color: tenantTheme.colors.primary.DEFAULT,
                        }}
                    />
                </IconButton>
            )}

            {/* Messages Icon */}
            {/* TODO REACTIVATE */}
            {tenantTheme.header?.showNotifications && false && (
                <Link href="/notifications">
                    <div className="relative">
                        <EnvelopeIcon
                            className="relative h-6 w-6 align-middle text-primary"
                            style={{
                                color: tenantTheme.colors.primary.DEFAULT,
                            }}
                        />
                        {hasNewMessages && (
                            <div className="absolute top-0 right-2">
                                <div className="absolute flex h-3 w-3 flex-col items-center justify-center rounded-full bg-white">
                                    <span
                                        className="h-2 w-2 rounded-full bg-success"
                                        style={{
                                            backgroundColor: tenantTheme.colors.success.DEFAULT,
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </Link>
            )}
            <CartIndicatorBubble />
        </div>
    );
};
