import React, { useMemo } from 'react';
import { IconButton, IIconButton } from './IconButton';
import { EAccess } from '../../../../interfaces/role/IAccess';
import { useAccess } from '../../access/hooks/useAccess';

export interface IIconButtonSpecial extends IIconButton {
    specialButtonCss?: string;
    hideAdmin?: boolean;
    access?: EAccess[];
    showControl: boolean;
}

export const IconButtonSpecial: React.FC<IIconButtonSpecial> = (props) => {
    const { children, wrapperCss, specialButtonCss, hideAdmin, access, showControl } = props;

    const { hasAccess, hasAnAdminRole } = useAccess();

    const showButton = useMemo(() => {
        if (hasAnAdminRole()) return true;
        return !!(access && hasAccess(access));
    }, [access, hasAccess, hasAnAdminRole]);

    const wrapperCssAggregated = useMemo(() => {
        return `${wrapperCss ?? ''} relative bg-cloudbarPrimary-light/30`;
    }, [wrapperCss]);

    return (
        <>
            {showButton && showControl && (
                <div className={`group ${specialButtonCss ?? ''}`}>
                    <IconButton outerCss="relative" wrapperCss={wrapperCssAggregated} {...props}>
                        <span className="text-cloudbarPrimary">{children}</span>
                    </IconButton>
                    {!hideAdmin && (
                        <div className="absolute -right-1 -bottom-1 rounded-full bg-cloudbarPrimary-light/20 group-hover:-bottom-2 group-hover:-right-0">
                            <span className="flex h-4 w-min select-none items-center justify-center transition-all min-w-4 text-[8px] text-cloudbarPrimary group-hover:px-1">
                                A<span className="hidden group-hover:inline">dmin</span>
                            </span>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};
