import { useRecoilValue } from 'recoil';
import { headerSearchSelector } from '../../../../general/state/appState';
import { useMemo } from 'react';
import { useCatalog } from '../../catalog/hooks/useCatalog';
import Fuse from 'fuse.js';
import { IProduct } from '../../../../../interfaces/product/IProduct';

export const useFilteredProductBase = () => {
    const { products } = useCatalog();

    const filter = useRecoilValue(headerSearchSelector);

    return useMemo(() => {
        if (filter && products) {
            // console.log(filter, products);

            const fuse: null | Fuse<IProduct> = new Fuse(products, {
                keys: ['name', 'description'],
                shouldSort: true,
                includeScore: true,
                threshold: 0.4,
                distance: 100,
                findAllMatches: false,
                minMatchCharLength: 3,
            });

            const fuzzed = fuse.search(filter);

            const filteredProducts: IProduct[] = [];

            // Map to IProducts[]
            fuzzed.forEach((item) => {
                filteredProducts.push(item.item);
            });
            return filteredProducts;
        }

        return [];
    }, [products, filter]);
};
