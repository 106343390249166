import React, { useCallback, useEffect, useMemo } from 'react';
import { INavigationColumnItem, useNavigationItems } from './mocks/navigationItems';

import { useWindowDimensions } from '../../../../../general/hooks/helper/useWindowDimensions';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { appStateRecoil, getShowNavigationPanel } from '../../../../../general/state/appState';
import { useBlockScrollLayer } from '../../../../../general/hooks/helper/useBlockScrollLayer';
import { NavigationColumn } from './NavigationColumn';
import { Transition } from '@headlessui/react';
import { useNavigation } from './hooks/useNavigation';
import { SkeletonLine } from '../../../../../general/elements/Skeletons/SkeletonLine';
import { useThemeStyles } from '../../../../../general/theme/TenantTheme/hooks/useThemeStyles';
import { NavigationPreviewColumn } from './NavigationPreviewColumn';
import { ICategory } from '../../../../../../interfaces/category/ICategory';
import { NavigationPanelAdminButton } from './elements/NavigationPanelAdminButton';
import { NavigationPanelTopItem } from './elements/NavigationPanelTopItem';
import { useTranslation } from '../../../../../general/Translations/hooks/useTranslation';
import Link from 'next/link';

interface INavigationProps {}

export const NavigationPanel: React.FC<INavigationProps> = () => {
    const setAppState = useSetRecoilState(appStateRecoil);
    const showNavigationPanel = useRecoilValue(getShowNavigationPanel);
    const { isDesktop } = useWindowDimensions();

    const { applyStyles } = useThemeStyles();
    const { getT } = useTranslation();

    const { selectedNavigationTab } = useNavigation();

    const { blockScroll, resetScroll } = useBlockScrollLayer();

    const navigationItems = useNavigationItems();

    const closeNavigation = useCallback(() => {
        setAppState((currVal) => {
            return { ...currVal, showNavigationPanel: undefined };
        });
        resetScroll();
    }, [setAppState, resetScroll]);

    useEffect(() => {
        if (showNavigationPanel && isDesktop) {
            blockScroll();
        }
    }, [showNavigationPanel, isDesktop, blockScroll]);

    const columnsCombined = useMemo(() => {
        const combined = selectedNavigationTab?.columns.reduce((acc, val) => acc.concat(val), []);

        if (!combined) {
            return [];
        }

        // Note: Temporary Solution to show TopLevel Category as well
        const tab: INavigationColumnItem = {
            categoryID: selectedNavigationTab?.id ?? '',
            label: selectedNavigationTab?.title ?? '',
            items: [],
            category: {
                id: selectedNavigationTab?.id ?? '',
            } as ICategory,
            link: `/catalog?id=${selectedNavigationTab?.id}`,
        };

        return [...combined, tab];
    }, [selectedNavigationTab?.columns, selectedNavigationTab?.id, selectedNavigationTab?.title]);

    return (
        <section className="relative w-screen" data-test-id={'navigation-panel-section'}>
            {selectedNavigationTab && (
                <button
                    onClick={closeNavigation}
                    className="absolute inset-0 top-0 left-0 h-screen w-screen bg-gray-400/20 backdrop-blur-sm"
                />
            )}

            <div
                className="relative w-full"
                onMouseLeave={() => {
                    closeNavigation();
                }}
            >
                {/* DivWrapper to show bg in w-screen */}
                <div
                    className="relative flex w-full flex-col items-center justify-center"
                    style={{
                        ...applyStyles({
                            // textColor: 'colors.applications.navigation.text.topMenuItems',
                            bgColor: 'colors.applications.navigation.background.subHeader',
                        }),
                    }}
                    data-test-id={'navigation-panel-wrapper'}
                >
                    {/* Button Row / Main Tabs */}
                    <div className="relative flex h-10 w-full max-w-7xl flex-row gap-6 px-6">
                        {!navigationItems && (
                            <>
                                <SkeletonLine height={32}></SkeletonLine>
                                <SkeletonLine height={32}></SkeletonLine>
                                <SkeletonLine height={32}></SkeletonLine>
                            </>
                        )}
                        {/* Each Tab Button */}
                        {navigationItems &&
                            navigationItems.map((tab, index) => {
                                return (
                                    <NavigationPanelTopItem
                                        key={`navigation-tab-${tab.id}`}
                                        tab={tab}
                                        closeNavigation={closeNavigation}
                                        isSelected={showNavigationPanel === tab.id}
                                        index={index}
                                    />
                                );
                            })}

                        <NavigationPanelAdminButton />
                    </div>

                    {/* Nav Panel */}
                    <Transition
                        as={'div'}
                        className={`relative top-[0px] flex w-full max-w-7xl flex-row gap-4 px-6 ${
                            selectedNavigationTab ? 'shadow-2xl' : ''
                        }`}
                        show={selectedNavigationTab !== null}
                        appear={selectedNavigationTab !== null}
                        enter="transition ease-out duration-500"
                        enterFrom="transform opacity-20 -translate-y-[20px]"
                        enterTo="transform opacity-100 translate-y-[0px]"
                        leave="transition ease-in duration-100"
                        leaveFrom="transform opacity-100"
                        leaveTo="transform opacity-0 translate-y-[20px]"
                        data-test-id="lower-navigation"
                    >
                        <div
                            className="absolute top-0 grid w-full grid-cols-[6fr,6fr] gap-4 shadow-2xl"
                            style={{
                                ...applyStyles({
                                    bgColor: 'colors.applications.navigation.background.DEFAULT',
                                }),
                            }}
                        >
                            <div className="relative flex flex-row gap-2">
                                {selectedNavigationTab &&
                                    selectedNavigationTab.columns.map((column, index) => {
                                        const selectedNavigationTabEmpty =
                                            selectedNavigationTab.columns.every((column2) => {
                                                return column2.length === 0;
                                            });

                                        if (selectedNavigationTabEmpty) {
                                            if (index > 0) {
                                                return;
                                            }

                                            return (
                                                <div
                                                    className="relative flex w-full flex-col gap-4 p-6"
                                                    key={`navigation-column-${index}`}
                                                >
                                                    <Link
                                                        className="font-semibold hover:underline"
                                                        onClick={() => {
                                                            closeNavigation();
                                                        }}
                                                        href={`/catalog?id=${selectedNavigationTab.id}`}
                                                    >
                                                        {getT('navigationGoToCategory')}
                                                    </Link>
                                                </div>
                                            );
                                        }

                                        return (
                                            <NavigationColumn
                                                closeFunction={closeNavigation}
                                                column={column}
                                                key={`navigation-column-${index}`}
                                                columnOrder={index + 1}
                                            />
                                        );
                                    })}
                            </div>
                            {/* Right: Preview Images */}
                            {columnsCombined && selectedNavigationTab?.id && (
                                <NavigationPreviewColumn
                                    columnData={columnsCombined}
                                    categoryId={selectedNavigationTab?.id}
                                />
                            )}
                        </div>
                    </Transition>
                </div>
            </div>
        </section>
    );
};
