import React, { PropsWithChildren, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { useKeyPress } from 'react-use';
import { useRecoilValue } from 'recoil';
import { getTenantTheme } from '../theme/ComputedStyles/recoil/computedStyles.recoil';
import { XMarkIcon } from '@heroicons/react/16/solid';

export interface IModalHeader extends PropsWithChildren {
    show?: boolean;
    closeFunction: () => void;
    centerItems?: boolean;
    hideXButton?: boolean;
}

export const ModalHeader: React.FC<IModalHeader> = (props) => {
    const { show, closeFunction, centerItems, hideXButton } = props;

    const predicate = (event: KeyboardEvent) => event.key === 'Escape';
    const escPressed = useKeyPress(predicate);

    const tenantTheme = useRecoilValue(getTenantTheme);

    useEffect(() => {
        if (show && escPressed && escPressed[0]) {
            closeFunction();
        }
    }, [closeFunction, escPressed, show]);

    return (
        <>
            <Transition
                className={`fixed left-0 top-[0px] z-[500] h-headerUpperDefault w-screen overflow-hidden bg-white backdrop-blur-2xl md:h-headerUpperMd`}
                show={show ?? false}
                appear={show ?? false}
                enter="transition ease-out duration-800"
                enterFrom="transform opacity-20"
                enterTo="transform opacity-100"
                leave="transition ease-in duration-500"
                leaveFrom="transform opacity-100"
                leaveTo="transform opacity-0"
            >
                <section className="relative top-0 left-0 flex overflow-x-hidden backdrop-blur-2xl h-headerUpperDefault md:h-headerUpperMd">
                    {/*Close Button*/}
                    <div className="absolute top-2 right-6 z-40 md:top-3.5">
                        {!hideXButton && (
                            <button
                                onClick={() => {
                                    closeFunction();
                                }}
                                className="relative"
                            >
                                <XMarkIcon
                                    className={`h-6 text-text-light hover:text-body-disabled`}
                                    style={{ color: tenantTheme.colors.body.dark }}
                                />
                            </button>
                        )}
                    </div>
                    {/*Inner Wrapper*/}
                    <div
                        className={`relative flex w-full ${
                            centerItems ? 'items-center justify-center' : ''
                        } overflow-y-auto`}
                    >
                        {props.children}
                    </div>
                </section>
            </Transition>
        </>
    );
};

export default ModalHeader;
