import React, { useCallback } from 'react';
import { useFilteredProductBase } from './hooks/useFilteredProductBase';
import { CatalogItem } from '../../elements/CatalogItem/CatalogItem';
import { useSetRecoilState } from 'recoil';
import { appStateRecoil } from '../../../general/state/appState';

export interface IHeaderSearchResult {
    testId?: string;
}

export const HeaderSearchResults: React.FC<IHeaderSearchResult> = () => {
    const searchResultList = useFilteredProductBase();

    const setAppState = useSetRecoilState(appStateRecoil);

    const closeSearch = useCallback(() => {
        setAppState((currVal) => {
            return { ...currVal, showSearch: false };
        });
    }, [setAppState]);

    return (
        <>
            {/* Search Text */}
            {searchResultList && searchResultList.length === 0 && (
                <div className="relative flex h-56 w-full flex-col items-center justify-center gap-4">
                    <h3 className="text-3xl font-bold text-neutral-500">
                        Keine Ergebnisse gefunden
                    </h3>
                    <p className="text-md">Bitte versuchen Sie es mit einem anderen Suchbegriff.</p>
                </div>
            )}
            {/*Search Results*/}
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {searchResultList &&
                    searchResultList.length > 0 &&
                    searchResultList.map((product) => {
                        if (!product.published) return <></>;

                        return (
                            <CatalogItem
                                product={product}
                                key={`search-result-item-${product.id}`}
                                showBorder={true}
                                onClick={closeSearch}
                            />
                        );
                    })}
            </div>
        </>
    );
};
