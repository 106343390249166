import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
    appStateRecoil,
    getShowSearch,
    headerSearchSelector,
    searchInputRefSelector,
} from '../../../general/state/appState';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/16/solid';
import { IconButton } from '../../../general/controls/button/IconButton';
import { useTranslationApply } from '../../../general/Translations/hooks/useTranslationApply';
import { useTranslation } from '../../../general/Translations/hooks/useTranslation';
import { useThemeStyles } from '../../../general/theme/TenantTheme/hooks/useThemeStyles';

export interface IHeaderSearchField {
    testId?: string;
}

export const HeaderSearchField: React.FC<IHeaderSearchField> = (props) => {
    const { testId } = props;
    const [search, setSearch] = useRecoilState(headerSearchSelector);
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const setAppState = useSetRecoilState(appStateRecoil);

    const showSearch = useRecoilValue(getShowSearch);

    const { applyStyles } = useThemeStyles();

    const { applyTranslation } = useTranslationApply();
    const { getT } = useTranslation();

    const [inputSearchRef, setInputSearchRef] = useRecoilState(searchInputRefSelector);

    const clearSearch = useCallback(() => {
        setSearch('');
    }, [setSearch]);

    const openSearch = useCallback(() => {
        setAppState((currVal) => {
            return { ...currVal, showSearch: true };
        });
    }, [setAppState]);

    useEffect(() => {
        if (!inputSearchRef && searchInputRef.current) {
            setInputSearchRef(searchInputRef.current);
        }
    }, [inputSearchRef, setInputSearchRef]);

    useEffect(() => {
        if (search.length > 0 && !showSearch) {
            openSearch();
        }
    }, [openSearch, search.length, showSearch]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
            const keyToCheck = isMac ? e.metaKey : e.ctrlKey;

            if (keyToCheck && e.key === 'k') {
                e.preventDefault();
                openSearch();
                searchInputRef.current?.focus();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [openSearch]);

    // ⌘+k pr CTRL+k
    const placeholderSuffix = useMemo(() => {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        return isMac ? '⌘+K' : 'CTRL+K';
    }, []);

    return (
        <div
            className="relative flex h-10 w-full flex-row items-center justify-center gap-2"
            data-test-id={`header-search-${testId ?? ''}`}
        >
            <input
                {...applyTranslation({
                    key: 'headerSearchPlaceholder',
                })}
                style={{
                    ...applyStyles({
                        textColor: 'colors.applications.headerSearch.text.DEFAULT',
                        bgColor: 'colors.applications.headerSearch.background.DEFAULT',
                        borderColor: 'colors.applications.headerSearch.border.DEFAULT',
                        rounded: 'auto',
                    }),
                }}
                ref={searchInputRef}
                placeholder={`${
                    getT('headerSearchPlaceholder') ?? 'Durchsuchen'
                }... ${placeholderSuffix}`}
                data-test-id={`header-search-input-${testId ?? ''}`}
                value={search}
                onChange={(e) => setSearch(e.target.value ?? '')}
                className="relative flex w-full flex-col items-center bg-transparent px-3 text-sm outline-0 border-1 placeholder:text-xs placeholder:tracking-wide md:placeholder:text-sm"
            />

            {/* Icon End */}
            {search.length === 0 && (
                <IconButton onClick={openSearch}>
                    <MagnifyingGlassIcon className="w-4 text-primary" />
                </IconButton>
            )}

            {/* Button with Icon Delete */}
            {search && (
                <div
                    className="relative flex h-6 items-center justify-center rounded-full pr-4 aspect-[1/1]"
                    data-test-id={'header-search-delete-button'}
                >
                    <IconButton onClick={clearSearch}>
                        <XMarkIcon className="w-4 text-primary" />
                    </IconButton>
                </div>
            )}
        </div>
    );
};
