import { useCallback, useEffect, useMemo, useState } from 'react';
import { IProduct } from '../../../../../../../interfaces/product/IProduct';
import { useCategoriesQuery } from '../../../../../hooks/category/useCategoriesQuery';
import { ICategory } from '../../../../../../../interfaces/category/ICategory';
import localforage from 'localforage';
import { ITranslations } from '../../../../../../../interfaces/translation/translations.interfaces';

export interface INavigationColumnItem {
    label: string;
    categoryID?: string | null;
    link: string | null;
    target?: string;
    tooltip?: boolean | null;
    tooltipText?: string | null;
    items?: INavigationColumnItem[];
    imageLinks?: INavigationImageLink[];
    category?: ICategory;
}

export interface INavigationImageLink {
    src: string;
    link: string;
}

export interface INavigationImageColumns {
    headline: string;
    description: string;
    productDemos: IProduct[];
}

export interface INavigationTab {
    id: string;
    headline?: string;
    label?: string;
    copyText: string;
    title?: string;
    columns: INavigationColumnItem[][];
    imageColumns?: INavigationImageColumns;
    href?: string;
    nameTranslation?: ITranslations;
}

export const useNavigationItems = () => {
    const { data: categories } = useCategoriesQuery();

    const lf = localforage.createInstance({
        name: 'cloudbar',
        storeName: 'categories',
        version: 1,
    });

    const [localCategories, setLocalCategories] = useState<ICategory[] | null>(null);

    useEffect(() => {
        if (categories || localCategories) {
            return;
        }

        void lf.getItem<ICategory[]>('categories').then((cachedCategories) => {
            if (cachedCategories) {
                setLocalCategories(cachedCategories);
            }
        });
    }, [categories, lf, localCategories]);

    useEffect(() => {
        if (categories) {
            void lf.setItem('categories', categories);
        }
    }, [categories, lf]);

    const categoriesMerged: ICategory[] | null = useMemo(() => {
        if (categories) {
            return categories;
        }

        if (localCategories) {
            return localCategories;
        }

        return null;
    }, [categories, localCategories]);

    const topLevelCategories = useMemo(() => {
        // filter all categories which does have no parentCategoryID
        return categoriesMerged?.filter((category) => {
            return category.parentCategoryID === null || category.parentCategoryID === undefined;
        });
    }, [categoriesMerged]);

    const buildLink = useCallback((id: string) => {
        return `/catalog?id=${id}`;
    }, []);

    return useMemo((): INavigationTab[] => {
        if (!topLevelCategories) return [];

        return topLevelCategories.map((topCategory) => {
            const column1: INavigationColumnItem[] = [];
            const column2: INavigationColumnItem[] = [];
            const column3: INavigationColumnItem[] = [];

            const totalChildren = topCategory.children?.length ?? 10;

            if (topCategory.children && topCategory.children.length > 0) {
                // todo handle rendering sub items in Categories...
                // eslint-disable-next-line no-console
                // console.log('CATEGORY HAS CHILDREN... !! !! !! Handle that..');

                let limitPerColumn;
                if (totalChildren <= 10) {
                    limitPerColumn = 10;
                } else if (totalChildren > 10 && totalChildren <= 20) {
                    limitPerColumn = 6;
                } else if (totalChildren > 20 && totalChildren <= 30) {
                    limitPerColumn = 8;
                } else if (totalChildren > 30) {
                    limitPerColumn = 10;
                } else {
                    limitPerColumn = 5;
                }

                // push first 5 children to column1, next 5 to column2, next 5 to column3
                topCategory.children.forEach((childCategory, index) => {
                    if (index < limitPerColumn) {
                        column1.push({
                            categoryID: childCategory.id,
                            label: childCategory.name,
                            link: buildLink(childCategory.id),
                            items: [],
                            category: childCategory,
                        });
                    } else if (index < limitPerColumn * 2) {
                        column2.push({
                            categoryID: childCategory.id,
                            label: childCategory.name,
                            link: buildLink(childCategory.id),
                            items: [],
                            category: childCategory,
                        });
                    } else if (index < limitPerColumn * 3) {
                        column3.push({
                            categoryID: childCategory.id,
                            label: childCategory.name,
                            link: buildLink(childCategory.id),
                            items: [],
                            category: childCategory,
                        });
                    }
                });
            }

            return {
                id: topCategory.id,
                label: topCategory.label,
                title: topCategory.label,
                headline: topCategory.label,
                imageColumns: {},
                columns: [column1, column2, column3],
                href: buildLink(topCategory.id),
                nameTranslation: topCategory.nameTranslation,
            } as INavigationTab;
        });
    }, [buildLink, topLevelCategories]);
};
