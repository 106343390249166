import React, { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
    appStateRecoil,
    getShowSearch,
    searchInputRefSelector,
} from '../../../general/state/appState';
import ModalHeader from '../../../general/modals/ModalHeader';
import ModalFullScreen from '../../../general/modals/ModalFullScreen';
import { getTenantTheme } from '../../../general/theme/ComputedStyles/recoil/computedStyles.recoil';
import { HeaderSearchResults } from './HeaderSearchResults';
import { HeaderSearchField } from './HeaderSearchField';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';

interface IHeaderSearch {
    testId?: string;
}

export const HeaderSearch: React.FC<IHeaderSearch> = () => {
    const setAppState = useSetRecoilState(appStateRecoil);
    const showSearch = useRecoilValue(getShowSearch);

    const tenantTheme = useRecoilValue(getTenantTheme);

    const inputSearchRef = useRecoilValue(searchInputRefSelector);

    const closeSearch = useCallback(() => {
        setAppState((currVal) => {
            return { ...currVal, showSearch: false, search: '' };
        });
    }, [setAppState]);

    const openSearch = useCallback(() => {
        setAppState((currVal) => {
            return { ...currVal, showSearch: true };
        });

        // Focus input field
        // TODO seems to have flaws
        if (inputSearchRef) {
            setTimeout(() => {
                inputSearchRef.focus();
            }, 500);
        }
    }, [inputSearchRef, setAppState]);

    return (
        <div className="relative flex w-full flex-row justify-end gap-1">
            <div className="relative hidden w-full lg:flex">
                <HeaderSearchField />
            </div>

            {/* Mobile Search Button */}
            <div className="relative my-auto flex lg:hidden">
                <MagnifyingGlassIcon
                    className="relative h-6 w-6 align-middle text-primary"
                    onClick={openSearch}
                    style={{
                        color: tenantTheme.colors.primary.DEFAULT,
                    }}
                />
            </div>

            {/* Mobile Search bar */}
            <ModalHeader show={showSearch} closeFunction={closeSearch} hideXButton={true}>
                <div className="relative m-auto flex items-center backdrop-blur-sm md:headerUpperMd max-h-headerUpperDefault max-w-[284px]">
                    <HeaderSearchField testId="Header" />
                </div>
            </ModalHeader>

            {/* Mobile Search results */}
            <ModalFullScreen
                show={showSearch}
                closeFunction={closeSearch}
                displayTopHeader={true}
                centerHorizontal={true}
            >
                <div
                    className="relative w-8/12 overflow-hidden pt-4 hover:overflow-y-auto"
                    style={{ scrollbarGutter: 'stable' }}
                >
                    <HeaderSearchResults />
                </div>
            </ModalFullScreen>
        </div>
    );
};
